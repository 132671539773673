<!-- 血液细胞形态诊断解决方案  -->
<template>
  <div id="blood">
    <Header></Header>
    <div class="blood-conter">
      <div class="blood-img">
        <Breadcrumb
          :productType="productType"
          :productName="productName"
        ></Breadcrumb>
        <!-- <img src="@/assets/image/product-detail/programme-4.png" alt="" /> -->
        <div class="illustrate-box">
          <div class="title wow animate__animated animate__fadeInUp">血液细胞形态诊断解决方案</div>
          <div class="line"></div>
          <ul class="keywords wow animate__animated animate__fadeInUp">
            <li>灵活</li>
            <li>高效</li>
            <li>完整</li>
            <li>精准</li>
            <li>稳定</li>
          </ul>
        </div>
      </div>
      <div class="blood-description">
        <p class="description-text wow animate__animated animate__fadeInUp">
          对外周血、骨髓涂片进行全自动数字化扫描及AI形态学分析，助力临床初筛。
        </p>
        <ul class="blood-icons">
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-1.png" alt="" />
            <div class="text">自动化推片染色</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-2.png" alt="" />
            <div class="text">数字切片扫描</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-3.png" alt="" />
            <div class="text">人工智能辅助诊断</div>
          </li>
          <li class="wow animate__animated animate__fadeInUp">
            <img src="@/assets/image/product-detail/icon-4.png" alt="" />
            <div class="text">报告签发</div>
          </li>
        </ul>
      </div>

      <RelatedProduct :swiperData="relatProd"></RelatedProduct>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "@/views/componets/Header.vue";
import Footer from "@/views/componets/Footer.vue";
import RelatedProduct from "../componets/RelatedProduct.vue";
import Breadcrumb from "@/views/componets/Breadcrumb.vue";
import api from "../../../api/api";

export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    RelatedProduct,
    Breadcrumb,
  },
  data() {
    return {
      productType: "解决方案",
      productName: "血液细胞形态诊断解决方案",
      relatProd: [
        {
          title: "骨髓/血液细胞形态学AI扫描分析系统",
          src: require("@/assets/image/product/blood-1.png"),
          path: "/product/marrow",
        },
        {
          title: "全自动推片染色机",
          src: require("@/assets/image/product/blood-2.png"),
          path: "/product/bloodPushing",
        },
      ],
      urlPath: "", // 当前路由
      allProdList: [], // 所有产品数据
      currentList: {}, // 当前页面数据
    };
  },
  async created() {
    await this.getPordList(1, 100, {});
    await this.getChooseId(this.currentList.id);
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {
    $route: {
      // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        // console.log(to.path);
        this.urlPath = to.path;
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    },
  },
  methods: {
    // 获取所有产品信息
    async getPordList(pageIndex, pageSize, params) {
      try {
        var res = await api.getPorductList(pageIndex, pageSize, params);
        if (res && res.data && res.data.code === 200) {
          this.allProdList = res.data.models;
          let presentProd = this.allProdList.filter((item) => {
            return item.summary == this.urlPath;
          });
          this.currentList = presentProd.length != 0 ? presentProd[0] : 0;
        } else {
        }
      } catch (error) {}
    },
    // 获取相关产品信息
    async getChooseId(prodId) {
      try {
        var res = await api.getProdRelationByid(prodId);
        if (res && res.data && res.data.code === 200) {
          if (res.data.models) {
            let result = res.data.models[0].relationID;
            if (result) {
              let relationArr = result.split(",");
              let tepmArr = [];
              relationArr.forEach((item) => {
                tepmArr.push(Number(item));
              });

              let relatedList = [];
              tepmArr.forEach((items) => {
                let secData = this.allProdList.filter((item) => {
                  return item.id == items;
                });
                if (secData.length != 0) {
                  relatedList.push(secData[0]);
                }
              });

              this.relatProd = relatedList;
            } else {
            }
          }
        } else {
        }
      } catch (error) {}
    },
  },
};
</script>
    
<style lang="scss">
#blood {
  width: 100%;
  height: 100%;
  .blood-conter {
    padding-top: 5rem;
    .blood-img {
      position: relative;
      height: 50rem;
      background-image: url("../../../assets/image/product-detail/programme-4.png");
      background-size: cover;
      // img {
      //   width: 100%;
      // }
      .illustrate-box {
        position: absolute;
        left: 18%;
        top: 30%;
        width: 21.875rem;
        .title {
          font-size: 1.8rem;
          font-family: "SourceHanSansSC-Medium";
          margin-bottom: 2.8125rem;
        }
        .line {
          width: 4.25rem;
          height: 3px;
          margin-bottom: 1.25rem;
          background: #177a73;
        }
        .keywords {
          display: flex;
          li {
            width: 18.4%;
            height: 2.1875rem;
            line-height: 2.1875rem;
            margin-right: 2%;
            color: #fff;
            background: #177a73;
            border-radius: 2.1875rem;
            text-align: center;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
    .blood-description {
      width: 100%;
      height: 37.5rem;
      box-sizing: border-box;
      background-image: url("../../../assets/image/product-detail/des-bg.png");
      background-size: cover;
      padding: 3.75rem 25%;
      .description-text {
        font-size: 1.5rem;
        line-height: 3rem;
        margin-bottom: 5rem;
        font-family: "OPlusSans3-Regular";
      }
      .blood-icons {
        display: flex;
        li {
          width: 14%;
          margin-right: 14.66%;
          &:nth-of-type(4n) {
            margin-right: 0;
          }
          img {
            width: 100%;
            margin-bottom: 1.25rem;
          }
          .text {
            text-align: center;
            font-family: "SourceHanSansSC-Medium";
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>